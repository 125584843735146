/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true);

require("@rails/ujs").start();
require("turbolinks").start();

import '../javascripts/filters.js';

console.log('Red 5 standing by');


jQuery(document).on('turbolinks:load', function() {

  jQuery('.survey__toggle').click(function() {
    jQuery(this).toggleClass('survey__toggle--active');
    jQuery('.survey__form').slideToggle();
  });

  jQuery('.stat_count__total a').click(function(e) {
    e.preventDefault();
    jQuery('.stat_count__details').fadeOut();
    jQuery(this).parent().siblings('.stat_count__details').fadeIn();
  });

  jQuery('.stat_count__details .close').click(function(e) {
    e.preventDefault();
    jQuery(this).parent().parent().fadeOut();
  });

});