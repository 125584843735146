$(document).on('turbolinks:load', function() {

  $('body').addClass('js');

  function filterIssues() {
    $(this).toggleClass('filter_options__toggle--hide');
    $('.issue-opened').slideToggle();
  };

  function filterPulls() {
    $(this).toggleClass('filter_options__toggle--hide');
    $('.git-pull-request').slideToggle();
  };

  function filterReviews() {
    $(this).toggleClass('filter_options__toggle--hide');
    $('.checklist').slideToggle();
  };

  $('.filter_options__toggle--issues').on('click', filterIssues);
  $('.filter_options__toggle--pullrequests').on('click', filterPulls);
  $('.filter_options__toggle--reviews').on('click', filterReviews);

});